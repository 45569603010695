import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterTwo";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { IoLogoWhatsapp } from "react-icons/io";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "EqualHealth",
    description:
      "上門醫療護理服務，我哋幫到你",
    buttonText: "聯絡我們",
    buttonLink: "https://wa.me/85246786768?text=你好,我想查詢有關服務",
  },
];
const ServiceListOne = [
  {
    icon: "icon-01.png",
    title: "日常護理",
    description: [
      "扶抱",
      "轉移",
      "防跌",
      "餵食",
      "餵藥",
      "沖涼",
      "抹身",
      "換片",
      "陪診"
    ],
  },
  {
    icon: "icon-02.png",
    title: "醫療護理",
    description: [
      "皮下注射",
      "血糖護理",
      "尿喉護理",
      "傷口護理",
      "腹膜透析",
      "造口護理",
      "鼻胃喉護理",
      "抽痰護理",
      "氣管造口護理"
    ],
  }
];

class OnsiteServices extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() { }
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Onsite Service" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}


        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <div className="bg_image bg_image--5">
              {SlideList.map((value, index) => (
                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ''}
                          {value.title ? <h1 className="title">{value.title}</h1> : ''}
                          {value.description ? <p className="description">{value.description}</p> : ''}
                          {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}<IoLogoWhatsapp size={20} /></a></div> : ''}
                          {/* Start CounterUp Area */}
                          <div className="rn-counterup-area">
                            <div className="container">
                              <div className="row">

                                <div className="col-lg-12">

                                </div>
                              </div>
                              <CounterOne />
                            </div>
                          </div>
                          {/* End CounterUp Area */}
                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Slider Area   */}

        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="text-center">
                    <div className="service service__style--equalHealth">
                      <h2 className="title">{val.title}</h2>
                      {val.description.map((descriptions, index) => (
                        <div className="row" key={index+"row"}>

                          <div className="left_box col-xl-5 col-lg-5 col-md-4 col-sm-4 col-4">
                            <p className="text-right">•</p>
                          </div>
                          <div className="right_box col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <p className="text-left"> {descriptions}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default OnsiteServices;
